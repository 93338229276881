import request from '@/utils/request'

export function generator(data, tableName) {
  console.log(data);
  return request({
    url: 'crm/generator?tableName=' + tableName,
    data,
    method: 'post'
  })
}
